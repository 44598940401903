.navbar {
  transition: 0.5s ease;
  margin-top: 0px;
  .navbar-brand {
    color: #fff;
    .nav-logo {
      max-width: 250px;
    }
  }
  .navbar-nav {
    .nav-item {
      .nav-link {
        font-size: 0.9em;
        font-weight: 500;
        letter-spacing: 0.1em;
        color: #bfbfbf;
        margin-left: 20px;
        position: relative;
        &::after{
          position: absolute;
          content: " ";
          width: 0%;
          height: 2px;
          background: #000;
          left: 0;
          bottom: 0;
          transition: 1s ease;
        }
        &:hover,&.active {
          &::after{
            width: 100%;
          }
        }
      }
      .base-nav{
        color: #fff;
        &::after{
          background: #fff;
        }
      }
    }
  }
}

.shrink {
  .navbar-brand {
    color: #000;
  }
  background: #fff !important;
  box-shadow: 0 0 1px 1px #e2e2e2;
  transition: 0.5s ease;
  margin-top: 0px;
  .navbar-nav {
    .nav-item {
      .nav-link {
        color: #000 !important;
        &::after{
          background: #000;
        } 
      }
    }
  }
}
