@import "~bootstrap/scss/bootstrap";

@import "fonts";
@import "variables";
@import "typography";
@import "components";
@import "carousel";
@import "navbar";
@import "footer";

body {
  font-family: 'Roboto', sans-serif;
  background: #fff;
  font-size: 16px;
}
section
{
  padding-top: 80px;
  padding-bottom: 80px;
}

.navbar{
  background: transparent;
}


@import "home.scss";
@import "about.scss";
@import "contact.scss";
@import "projects.scss";
@import "services.scss";

#workshop-details{
  .subhead{
    margin-bottom: 70px;
  }
  .workshop-row{
    .content-column{
      padding: 100px 40px;
      text-align: center;
      background: #eaeaea;
    }
  }
}

#workshop-signup{
  padding-top: 0;
  .heading{
    margin-bottom: 50px;
  }
  .btn-main{
    background: #313c4a;
    border: 1px solid #313c4a;
    width: 100%;
    color: #fff;
    &:hover{
      background: #fff;
      color: #313c4a;
    }
  }
}

@import "responsive.scss";
