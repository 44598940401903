$screen-xs-max: 350px;
$screen-sm-min: 768px;
$screen-md-min: 992px;
$screen-lg-min: 1200px;

$font-weight-thin:          100;
$font-weight-exlight:       200;
$font-weight-light:         300;
$font-weight-regular:       400;
$font-weight-medium:        500;
$font-weight-semibold:      600;
$font-weight-bold:          700;
$font-weight-exbold:        800;
$font-weight-black:         900;

$color-blue: #075ffe;
$color-white: #ffffff;
$color-black: #000;
$color-text-primary: #212529;
$color-dark: #212529;
$color-gray: #40495c;
$color-gray-dark: #343C49;
$color-gray-light: #f7f7f7;
$color-footer-border: #e4e4e4;
