.button {
  padding: 6px 12px;
  border: none;
  outline: none;
  cursor: pointer;
  &:focus,
  &:active {
    outline: none;
  }
}

.button-primary {
  padding: 10px 20px;
  color: $color-white;
  background: $color-blue;
  border: none;
  outline: none;
}

.button-black {
  color: $color-dark;
  background: transparent;
  border: 2px solid $color-black;
  transition: 0.5s ease;
  &:hover {
    background: $color-black;
    color: $color-white;
  }
}

.main-head {
  padding-top: 30px;
  font-size: 3em;
}

.main-sub {
  padding-top: 10px;
  color: #777777;
  letter-spacing: 0.1em;
  font-weight: normal;
}

.top-header {
  height: 100%;
  background: $color-black;
  background-attachment: fixed;
  background-size: cover;
  .header-contents {
    padding-top: 200px;
    padding-bottom: 50px;
  }
  .header-icon {
    color: $color-white;
    font-size: 3rem;
  }
  .header-text {
    margin-top: 20px;
    font-weight: 700;
    color: $color-white;
    font-size: 2.5rem;
    .light {
      font-weight: 400;
    }
  }
}
