@media only screen and (min-device-width: 320px) and (max-device-width: 1024px) {
  #carousel-main .carousel-image {
    width: unset;
    height: 100%;
  }
  #abt-main {
    .content {
      .content-col {
        padding-bottom: 30px;
        .btn-main {
          background: #ffffff;
        }
      }
      .img-fluid {
        width: 100%;
      }
    }
  }
  #home-program {
    padding-bottom: 40px;
  }
  .industries-card {
    .i-image-1,
    .i-image-2 {
      display: none;
    }
  }
  #footer {
    text-align: center;
    .soc-holder {
      .col-md-3 {
        text-align: -webkit-center;
        .icon {
          float: none;
        }
      }
    }
    .links {
      padding-right: 20px;
    }
  }
  .navbar{
    background: #ffffff;
    .navbar-collapse{
      padding: 20px 0px;
      text-align: center;
    }
  }
  .navbar .navbar-nav .nav-item .base-nav{
    color: #000000;
  }

  #about-content{
    .content-heading{
      padding-bottom: 20px;
    }
  }

  #about-features{
    .f-image{
      width: 100%;
    }
  }

  #about-qualities{
    .button-black{
      margin-bottom: 50px;
    }
  }

  #services-serives{
    .f-heading{
      padding-top: 40px;
    }
  }

  #services-industries{
    .i-image{
      img{
        width: 100%;
      }
    }
    .i-card-holder{
      margin-top: 15px;
    }
  }

  #contact-details{
    .contact-card{
      padding-top: 20px !important;
    }
  }
}

@media only screen and (min-width: 1224px) {}

@media only screen and (min-width: 1824px) {}
