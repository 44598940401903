#footer {
  padding-top: 0;
  padding-bottom: 0;
  background: $color-gray-light;
  color: $color-black;
  .button {
    background: transparent;
    color: $color-black;
    padding: 15px 40px;
    font-weight: normal;
    font-size: 0.8em;
    letter-spacing: 0.1em;
    transition: 0.3s ease;
    &:hover {
      color: $color-white;
      background: $color-black;
    }
  }
  .pb-30 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .qk-links {
    border-right: 1px solid $color-footer-border;
    padding-right: 0;
    .soc-holder {
      padding-top: 30px;
    }
    .icon {
      float: left;
      margin: 0 20px 20px 0;
      cursor: pointer;
      background: $color-black;
      color: $color-white;
      padding: 15px 20px;
      border-radius: 50%;
      border: 1px solid $color-black;
      width: 55px;
      height: 55px;
      transition: 0.5s ease;
      &:hover {
        transition: 0.5s ease;
        background: transparent;
        color: #000;
      }
    }
  }
  .links {
    padding-top: 20px;
  }
  .m-head {
    border-bottom: 1px solid $color-footer-border;
    padding-bottom: 30px;
    color: $color-black;
  }
  .nav-link {
    padding: 8px 0px;
    letter-spacing: 0.05em;
    font-size: 1em;
    color: $color-black;
  }
  .get-touch {
    border-right: 1px solid $color-footer-border;
    padding: 0;
    padding: 0;
    .fa {
      font-size: 0.9em;
    }
    .fas {
      font-size: 0.9em;
    }
    .icon {
      float: left;
      margin: 0 20px 20px 0;
      cursor: pointer;
      background: $color-black;
      color: $color-white;
      padding: 7px 12px;
      border-radius: 50%;
      width: 40px;
      height: 40px;
    }
    .m-head {
      padding-left: 50px;
    }
    .nav-link {
      padding-left: 50px;
      font-size: 1.1em;
      .link {
        line-height: 40px;
      }
    }
    .button {
      margin-left: 50px;
      margin-top: 20px;
    }
  }
  .map-holder {
    padding: 0;
    .mp{
        padding-top: 60px;
        padding-bottom: 8px;
    }
    .m-head {
      padding-left: 50px;
    }
    .map-cont {
      padding-left: 8px;
      .map {
        width: 100%;
        height: 290px;
      }
    }
  }
}

footer {
  padding-top: 30px;
  padding-bottom: 30px;
  h5 {
    color: #fff;
    font-size: 1em;
    font-weight: lighter;
    opacity: 0.8;
  }
  background:$color-gray-dark;
}
