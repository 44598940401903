.contact-header{
  background-image: url('../assets/header-2.jpg')
}
.program-header{
  background-image: url('../assets/campus.jpg')
}
#contact-details {
  padding-bottom: 0;
  .contact-cards {
    .contact-card {
      padding: 0 20px;
      height: 100%;
      .holder {
        height: 100%;
        padding: 50px 20px;
        box-shadow: 0 0 1px 0px #00000040;
        .c-icon {
          font-size: 3rem;
          margin-bottom: 10px;
        }
        &.email, &.phone{
          padding-top: 100px;
        }
      }
    }
  }
}
#contact-form{
  .contact-form{
    padding-top: 30px;
    .contact-label{
      display: block;
    }
    .contact-input,.contact-textarea{
      width: 100%;
      border: 2px solid $color-dark;
      padding: 5px;
      margin-bottom: 20px;
    }
    .contact-btn{
      max-width: 300px;
      text-align: center;
    }
  }
}
#contact-map{
  .map{
    width: 100%;
    height: 400px;
  }
}
