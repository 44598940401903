.projects-header{
  background-image: url('../assets/header-3.jpg')
}
#projects{
  .types{
    padding: 20px 0px;
    .type{
      background: transparent;
      color: $color-black;
      border-radius: 0;
      padding: 5px 20px;
      &:focus{
        box-shadow: none;
      }
    }
    .active{
      background: $color-black;
      color: $color-white;
    }
  }
  .projects-holder{
    margin-top: 40px;
    .content{
      margin-bottom: 20px;
      .img-holder{
        padding-bottom: 10px;
      }
    }
  }
}
