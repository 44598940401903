.services-header{
  background-image: url('../assets/header-4.jpg')
}

#services-services {}

#services-moto {
  background: $color-gray-light;
  // margin-bottom: 50px;
  padding: 0;
  .moto-image {
    background: url('../assets/mission.jpg');
    background-position: center;
    background-size: cover;
  }
  .moto-content {
    padding: 70px 50px;
  }
}

#services-industries {
  background: url('../assets/bg-industries.jpg');
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  .head {
    .heading-section,
    .heading-content {
      color: $color-white;
    }
  }
  .i-cards{
    padding-top: 40px;
  }
  .i-card {
    height: 100%;
    background: $color-white;
    .i-content {
      padding: 30px;
    }
  }
}
