.about-header {
  background-image: url('../assets/header-1.jpg');
}

#about-content {
  padding-bottom: 0;
  .content-heading {
    font-weight: 900;
    margin-top: 40px;
  }
}

#about-features {
  .features {
    padding-top: 20px;
    .feature {
      margin: 10px;
    }
    .f-heading {
      margin-top: 20px;
      font-weight: 600;
    }
  }
}

#about-qualities {
  padding-bottom: 0;
  .qualities-heading {
    font-weight: 700;
    margin-top: 30px;
    margin-bottom: 20px;
    font-size: 1.2rem;
    line-height: 1.5;
  }
  .qualities {
    .quality {
      .q-heading {
        font-weight: 600;
        position: relative;
        padding-bottom: 10px;
        &::after {
          content: " ";
          position: absolute;
          left: 0;
          bottom: 0;
          width: 30%;
          height: 3px;
          background: $color-black;
        }
      }
      .q-content {
        font-weight: 400;
        margin-bottom: 10px;
      }
      .q-list {
        padding-left: 20px;
        li {
          font-weight: 300;
        }
      }
    }
  }
}
#about-team{
  .team{
    margin-top: 40px;
    .member{
      padding: 0px 80px;
      .m-image{
        border-radius: 100%;
      }
      .m-name{
        margin-top: 30px;
      }
    }
  }
}
