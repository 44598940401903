#carousel-main
{
    width: 100%;
    padding-top: 100px;
    height: 850px;
    overflow: hidden;
    .carousel-control-prev
    {
        width: 30%;
        cursor :  url("../assets/left-arrow.svg"),pointer;
    }
    .carousel-control-next
    {
        cursor :  url("../assets/right-arrow.svg"),pointer;
        width: 30%;
    }
    .carousel-image{
        width: 100%;
    }
    .carousel-caption
    {
        width: 100%;
        top: 30%;
        left: 0;
        right: 0;
        text-align: center;
        h3
        {
            color: #fff;
            font-size: 4em;
            font-weight: bolder;
            letter-spacing: 0.1em;
        }
        .carousel-sub
        {
            margin: 0 auto;
            max-width: 500px;
            p
            {
                color: #fff;
                font-style: italic;
                font-weight: lighter;
                font-size: 1.0em;
                letter-spacing: 0;
            }
        }
        @media screen and (max-width: $screen-xs-max)
        {
            h3
            {
                font-size: 2em;
            }
        }
    }
}
