#values-main {
  background: $color-gray-light;
  .values {
    .value {
      color: $color-gray;
      padding: 0 10px;
      .icon {
        font-size: 4rem;
        margin-bottom: 20px;
      }
      .heading {
        color: $color-gray;
        font-weight: 700;
      }
      .text {
        color: $color-gray;
      }
    }
  }
}

#abt-main {
  .sub-since {
    padding-top: 10px;
    font-size: 1em;
    letter-spacing: 0.1em;
  }
  .btn-main {
    margin-top: 10px;
    border: 2px solid #000;
    border-radius: 0;
    transition: 0.5s ease;
    &:hover {
      transition: 0.5s ease;
      background: #000;
      color: #fff;
    }
  }
  .serv-link {
    a {
      text-decoration: none;
      transition: 0.5s ease;
      color: rgb(21, 113, 136);
      &:hover {
        transition: 0.5s ease;
        color: rgb(21, 113, 136);
      }
    }
    padding-top: 30px;
  }
  .serv-holder {
    padding-top: 30px;
    padding-left: 30px;
    .main-head-2 {
      font-size: 1.8em;
      letter-spacing: 0.1em;
      padding-bottom: 10px;
      font-weight: 600;
      line-height: 1.5em;
    }
    .nav {
      .nav-item {
        padding-top: 15px;
        padding-bottom: 15px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.301);
        a {
          text-decoration: none;
          opacity: 0.9;
          font-weight: light;
          color: #000;
        }
      }
    }
  }
}

#industries-main {
  .industries {
    margin-top: 30px;
  }
  .industries-card-holder {
    padding: 10px;
    .industries-card {
      height: 100%;
      display: flex;
    }
    .industries-card>div {
      flex-grow: 1;
      flex-basis: 0;
    }
    .i-image-1 {
      background: url('../assets/laser.png');
      background-size: cover;
      background-position: center;
    }
    .i-image-2 {
      background: url('../assets/sla.png');
      background-size: cover;
      background-position: center;
    }

    .i-content {
      padding: 50px 20px;
      .i-heading{
        font-weight: 600;
      }
    }
  }
}

#serv-main {
  .main-head {
    letter-spacing: 0.05em;
  }
  .serv-holder {
    padding-top: 30px;
    .content{
      padding: 10px 0px;
    }
    .serv-head {
      text-align: left;
      padding-top: 20px;
    }
    .serv-sub {
      font-weight: 400;
      text-align: left;
    }
  }
  .btn-holder {
    margin-top: 30px;
  }
}

#home-program{
  background: #ececec;
  padding: 0;
  .contents{
    padding-top: 70px;
    .heading{
      padding-bottom: 10px;
    }
  }
}

#data-main{
  background: #f7f7f7;
  color: #3f495f;
  .heading{
    margin-bottom: 25px;
  }
}

#brand-main{
  background: url('../assets/bg-header.png');
  background-size: cover;
  background-position: center;
  .heading{
    margin-bottom: 25px;
    color: #fff;
    font-weight: 700;
  }
  .text{
    font-weight: 400;
    margin-bottom: 35px;
    color: #fff;
  }
  .button-main{
    color: #fff;
    border: 1px solid #fff;
    padding: 10px 20px;
    transition: 0.3s ease;
    &:hover{
      background: #fff;
      color: #006FBD;

    }
  }
}
