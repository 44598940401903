h1,h2,h3,h4,h5,h6,p,a{
  color: $color-text-primary;
}
a{
  &:hover{
    text-decoration: none;
    color: $color-text-primary;
  }
}
h1{
  font-weight: 300;
  font-size: 2rem;
}
h2{

}
h3{

}
h4{

}
h5{
  font-size: 1.1rem;
}
h6{

}
p{
  font-weight: 300;
  letter-spacing: 1.2px;
  line-height: 1.5rem;
}

.heading-section{
  font-weight: 600;
  .light-text{
    font-weight: 300;
  }
}
